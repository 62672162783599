<template>
  <div>
    <div class="level">
      <div class="level-left" style="width: 45%">
        <div class="field" style="width: 100%">
          <label class="label is-size-7">Users</label>
          <div class="control">
            <div class="select is-multiple" style="width: 100%">
              <select
                id="lstUnassignedClients"
                multiple
                v-model="selectedUnassignedClients"
                :size="listSize"
                style="width: 100%"
              >
                <option v-for="id in unassignedClients" :value="id" :key="id">
                  {{ clientsMap.get(id) ? clientsMap.get(id).name : null }} ({{ clientsMap.get(id) ? clientsMap.get(id).email : null }})
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="level-center" style="width: 10%">
        <div id="buttonblock" class="has-text-centered">
          <div class="block">
            <a v-on:click="moveRight" class="button is-accent is-outlined">
              <i class="fas fa-caret-right fa-2x"></i>
            </a>
          </div>
          <div class="block">
            <a v-on:click="moveLeft" class="button is-accent is-outlined">
              <i class="fas fa-caret-left fa-2x"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="level-right" style="width: 45%">
        <div class="field" style="width: 100%">
          <label class="label is-size-7">
            Allowed to Complete Questionnaire
            <span class="has-text-warning" style="padding-left: 3px">*</span>
          </label>
          <div class="control">
            <div class="select is-multiple" style="width: 100%">
              <select
                id="lstSendToUsers"
                multiple
                v-model="selectedAssignedClients"
                :size="listSize"
                style="width: 100%"
              >
                <option v-if="input.assignedClients.length === 0" disabled>
                  Select one or more clients
                </option>
                <option
                  v-for="id in input.assignedClients"
                  :value="id"
                  :key="id"
                >
                  {{ clientsMap.get(id) ? clientsMap.get(id).name : null }} ({{ clientsMap.get(id) ? clientsMap.get(id).email : null }})
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'questionnaireClients',
  props: ['institutionId', 'clients', 'selectedClients'],
  data: function () {
    return {
      input: {
        assignedClients: [],
        institutionId: 0
      },
      customToolbar: [['bold', 'italic', 'underline']],
      activetab: 0,
      listSize: 7,
      selectedAssignedClients: [],
      selectedUnassignedClients: [],
      clientsMap: new Map()
    }
  },
  mounted () {
    this.getInstitutionClients()
  },
  watch: {
    clients: {
      handler: function (val, oldVal) {
        this.getInstitutionClients()
      },
      deep: true
    }
  },
  computed: {
    assignedClients () {
      return [...this.input.assignedClients.keys()]
    },
    unassignedClients () {
      if (!this.clientsMap || this.clientsMap.size === 0) return
      let unassignedClients = []
      this.clientsMap.forEach(entry => {
        if (!this.input.assignedClients.includes(entry.id)) {
          unassignedClients.push(entry.id)
        }
      })

      return unassignedClients
    }
  },
  methods: {
    async getInstitutionClients () {
      if (!this.clients || this.clients.length === 0) return
      try {
        this.clientsMap = new Map(this.clients.map((i) => [i.id, { ...i, name: i.firstName + ' ' + i.lastName, listName: i.firstName + ' ' + i.lastName }]))
        if (this.clientsMap && this.clientsMap.size > 0 && this.selectedClients) {
          this.selectedClients.forEach(u => {
            this.input.assignedClients.push(u.clientId)
          })
        }
      } catch (_) {
        // console.log('error')
        // console.log(error)
      }
    },
    buildMembership () {
      return { users: this.input.assignedClients }
    },
    moveRight (event) {
      this.input.assignedClients.push(...this.selectedUnassignedClients)
      this.input.assignedClients = this.input.assignedClients.sort()
      this.selectedUnassignedClients = []
      this.$emit('invitationsUpdated', this.buildMembership())
    },
    moveLeft (event) {
      this.input.assignedClients = this.input.assignedClients.filter(
        (x) => !this.selectedAssignedClients.includes(x)
      )
      this.input.assignedClients = this.input.assignedClients.sort()
      this.selectedAssignedClients = []
      this.$emit('invitationsUpdated', this.buildMembership())
    }
  }
}
</script>
