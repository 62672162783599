<template>
    <input v-model="displayValue" @blur="handleInputState" @focus="handleInputState" :maskType="maskType" @keydown="handleInputState" />
</template>

<script>
export default {
  name: 'MaskedInput',
  props: {
    value: null,
    maskType: String
  },
  data: function () {
    return {
      inputFocused: false
    }
  },
  methods: {
    handleInputState (event) {
      this.inputFocused = event.type === 'focus'

      this.$emit(event.type, event)
    },
    unmask (value) {
      // handle unmask formatting bt type prop
      if (this.maskType === 'currency') {
        if (value) {
          value = parseFloat(value.toString().replace(/[^\d.]/g, ''))
        }
        return isNaN(value) ? 0 : value
      } else {
        return ''
      }
    },
    mask (value) {
      // handle mask formatting by type prop
      if (this.maskType === 'currency') {
        if (value) {
          return '$' + value.toLocaleString()
        }
        return ''
      } else {
        return ''
      }
    }
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.inputFocused) {
          if (this.value) {
            return this.value.toString()
          } else {
            return ''
          }
        } else {
          return this.mask(this.value)
        }
      },
      set: function (modifiedValue) {
        this.$emit('input', this.unmask(modifiedValue))
      }
    }
  }
}
</script>
