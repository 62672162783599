<template>
  <div>
    <table
      :class="['dt-custom v-datatable-light no-borders', css.table]"
      style="margin-bottom: 0"
    >
      <thead :class="css.thead" :style="theadStyle">
        <tr :class="css.theadTr" style="min-width: 100%">
          <th
            v-for="(item, columnIndex) in headers"
            :key="item.label + tableDataHash"
            :class="
              headerItemClass(item, [
                css.theadTh,
                `header-column-${columnIndex}`,
              ])
            "
            :style="getColumnWidth(item)"
          >
            <!-- header free text -->
            <div
              v-if="!isFieldSpecial(item.name) && !item.customHeader"
              :class="[css.thWrapper, `is-size-7 header-column-${columnIndex}`]"
              @click="orderBy(item.name)"
            >
              {{ item.label }}
              <span v-if="item.required" style="color: red">*</span>
              <div
                v-if="item.sortable"
                :class="arrowsWrapper(item.name, css.arrowsWrapper)"
              >
                <div v-if="showOrderArrow(item, 'desc')" :class="css.arrowUp" />
                <div
                  v-if="showOrderArrow(item, 'asc')"
                  :class="css.arrowDown"
                />
              </div>
            </div>
            <!-- end header free text -->
            <!-- header custom header -->
            <div
              v-if="!isFieldSpecial(item.name) && item.customHeader"
              :class="[css.thWrapper, `header-column-${columnIndex}`]"
              @click="orderBy(item.name)"
            >
              <slot
                v-if="item.customHeader"
                :header-data="item"
                :name="customHeaderName(item)"
              />
              <div
                v-if="item.sortable"
                :class="arrowsWrapper(item.name, css.arrowsWrapper)"
              >
                <div v-if="showOrderArrow(item, 'desc')" :class="css.arrowUp" />
                <div
                  v-if="showOrderArrow(item, 'asc')"
                  :class="css.arrowDown"
                />
              </div>
            </div>
            <!-- end header custom header -->
            <!-- especial field -->
            <div
              v-if="
                isFieldSpecial(item.name) &&
                extractArgs(item.name) === 'checkboxes'
              "
              :class="css.thWrapperCheckboxes"
            >
              <input
                type="checkbox"
                :disabled="disabled"
                :class="css.checkboxHeader"
                :checked="checkedAll"
                @click="checkAll"
              />
            </div>
            <!-- end especial field -->
          </th>
        </tr>
      </thead>
      <tbody :class="css.tbody" :style="tbodyStyle">
        <!-- spinner slot -->
        <template v-if="isLoading">
          <tr :class="css.tbodyTrSpinner">
            <td :colspan="headers.length" :class="css.tbodyTdSpinner">
              <slot name="spinner" />
            </td>
          </tr>
        </template>
        <!-- end spinner slot -->
        <!-- table rows -->
        <template v-else-if="tableData.length">
          <tr
            v-for="(item, index) in tableData"
            :key="item.id + tableDataHash"
            :class="[
              css.tbodyTr,
              `row-${index}`,
              item.isDeleted === 1 ? 'is-deleted' : '',
            ]"
            style="min-width: 100%"
          >
            <td
              v-for="(key, columnIndex) in headers"
              :key="`${index}-${key.name}`+ tableDataHash"
              :class="[css.tbodyTd, `column-${columnIndex}`]"
              :style="{ width: 'auto', minWidth: key.width }"
            >
              <slot
                v-if="
                  isFieldSpecial(key.name) &&
                  extractArgs(key.name) === 'actions'
                "
                :name="extractActionID(key.name)"
                :row-data="item"
                :row-index="index"
              />
              <input
                v-if="
                  isFieldSpecial(key.name) &&
                  extractArgs(key.name) === 'checkboxes'
                "
                :disabled="item.isDeleted === 1 || disabled"
                type="checkbox"
                :class="css.checkbox"
                :row-data="item"
                :row-index="index"
                :checked="checkedAll || isCheckedItem(item)"
                @click="checkItem(item, $event)"
                :is-invalid-field="item['has' + key.name + 'error']"
              />
              <slot
                v-if="key.customElement"
                :row-data="item"
                :row-index="index"
                :name="customElementName(key)"
              />
              <template v-else-if="key.format == 'date' && key.isEditable">
                <kendo-datepicker
                  v-if="!disabled"
                  :disabled="item.isDeleted === 1 || disabled"
                  @change="preValidationRow(item)"
                  v-model="item[key.name]"
                  :format="'yyyy-MM-dd'"
                  :is-invalid-field="item['has' + key.name + 'error']"
                >
                </kendo-datepicker>
                <span v-else>
                  <input
                    disabled="true"
                    :is-invalid-field="item['has' + key.name + 'error']"
                    type="text"
                    :value="
                      item[key.name] != undefined
                        ? moment(item[key.name]).format('YYYY-MM-DD')
                        : ''
                    "
                  />
                </span>
              </template>
              <template v-else-if="key.format == 'phone' && key.isEditable"
                ><input
                  :disabled="item.isDeleted === 1 || disabled"
                  v-model="item[key.name]"
                  :is-invalid-field="item['has' + key.name + 'error']"
              /></template>
              <template v-else-if="key.format && key.isEditable"
                ><masked-input
                  :disabled="item.isDeleted === 1 || disabled"
                  v-model="item[key.name]"
                  :maskType="key.format"
                  :is-invalid-field="item['has' + key.name + 'error']"
                ></masked-input
              ></template>
              <template v-else-if="key.format && !key.isEditable">{{
                key.format(item[key.name])
              }}</template>
              <template v-else-if="key.isEditable"
                >
                <input
                  :disabled="item.isDeleted === 1 || disabled"
                  v-model="item[key.name]"
                  :is-invalid-field="item['has' + key.name + 'error']"
              /></template>
              <template v-else>{{ item[key.name] }}</template>
            </td>
          </tr>
          <tr>
            <td :colspan="headers.length + 1" style="text-align: right">
              <a
                v-if="!disableAddRow && !disabled"
                :disabled="
                  tableData.length >= limit || disableAddRow || disabled
                "
                class="button is-light"
                v-on:click="addRow"
                >Add row</a
              >
            </td>
          </tr>
        </template>
        <!-- end table rows -->
        <!-- table not found row -->
        <template v-else>
          <tr :class="css.notFoundTr">
            <td :colspan="headers.length" :class="css.notFoundTd">
              {{ notFoundMessage }}
            </td>
          </tr>
          <tr>
            <td :colspan="headers.length + 1" style="text-align: right">
              <a
                v-if="!disableAddRow"
                :disabled="tableData.length >= limit || disableAddRow"
                class="button is-light"
                v-on:click="addRow"
                >Add row</a
              >
            </td>
          </tr>
        </template>
        <!-- end table not found row -->
      </tbody>
      <tfoot v-if="hasSlots" :class="css.tfoot">
        <tr :class="css.tfootTr">
          <th :colspan="headers.length" :class="css.tfootTd">
            <div :class="css.footer">
              <slot name="ItemsPerPage" />
              <slot name="pagination" />
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import MaskedInput from './MaskedInput'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import { bus } from '@/main'
import crypto from 'crypto'

export default {
  name: 'DataTable',
  components: {
    MaskedInput,
    'kendo-datepicker': DatePicker
  },
  props: {
    headerFields: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    sortField: {
      type: String,
      default: null
    },
    sort: {
      type: String,
      default: null
    },
    notFoundMsg: {
      type: String,
      default: null
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    css: {
      type: Object,
      default: () => ({
        table: '',
        thead: 'thead',
        theadTr: 'thead-tr',
        theadTh: 'thead-th',
        tbody: 'tbody',
        tbodyTr: 'tbody-tr',
        tbodyTrSpinner: 'tbody-tr-spinner',
        tbodyTd: 'tbody-td',
        tbodyTdSpinner: 'tbody-td-spinner',
        tfoot: 'tfoot',
        tfootTd: 'tfoot-td',
        tfootTr: 'tfoot-tr',
        footer: 'footer',
        thWrapper: 'th-wrapper',
        thWrapperCheckboxes: 'th-wrapper-checkboxes',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow-up',
        arrowDown: 'arrow-down',
        checkboxHeader: 'checkbox-header',
        checkbox: 'checkbox',
        notFoundTr: 'not-found-tr',
        notFoundTd: 'not-found-td'
      })
    },
    listenOn: {
      type: String,
      default: 'update-tables'
    },
    tableHeight: {
      type: String,
      default: null
    },
    defaultColumnWidth: {
      type: String,
      default: '150px'
    },
    onlyShowOrderedArrow: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    },
    disableAddRow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateRow: {
      type: Function,
      default: () => () => true,
      required: false
    },
    tableDataHash: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      sortedField: this.sortField,
      sortedDir: this.sort,
      notFoundMessage: this.notFoundMsg,
      loading: this.isLoading,
      checkedAll: false,
      itemsChecked: [],
      hasLoaded: true,
      timer: null
    }
  },
  watch: {
    tableData: {
      handler: function (val, oldVal) {
        this.updateTables()
      },
      deep: true
    },
    tableDataHash: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.hasLoaded = false
          this.updateTables()
          setTimeout(() => {
            this.hasLoaded = true
          }, 50)
        }
      },
      deep: true
    }
  },
  computed: {
    hasSlots: function () {
      return (
        this.$slots.pagination !== undefined ||
        this.$slots.ItemsPerPage !== undefined
      )
    },

    headers: function () {
      if (
        this.headerFields &&
        this.headerFields.constructor === Array &&
        this.headerFields.length
      ) {
        return Object.keys(this.headerFields).map((key) => {
          const field = this.headerFields[key]
          if (typeof field === 'string') {
            return {
              label: field,
              name: field,
              width: this.headerFields[key].width
            }
          }
          return field
        })
      }
      return []
    },
    tbodyStyle: function () {
      if (this.tableHeight) {
        return {
          height: this.tableHeight,
          display: 'block',
          overflowX: 'auto'
        }
      }
      return null
    },
    theadStyle: function () {
      return this.tableHeight ? { display: 'block' } : null
    }
  },

  mounted: function () {
    this.isLoading = true

    setTimeout(() => {
      bus.$on(this.listenOn, (data) => {
        this.tableData = data
        this.$forceUpdate()
      })
      this.updateTables()

      this.timer = setInterval(() => {
        this.$emit('validate-data', true)
      }, 500)

      this.isLoading = false
    }, 500)
  },

  beforeDestroy: function () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    hash (item) {
      return crypto.createHash('sha256').update(JSON.stringify(item)).digest('hex')
    },
    encode (item) {
      return '' // JSON.stringify(item)
    },
    inputHasError (row, key) {
      return row['has' + key.name + 'error'] === true || row['has' + key.name + 'error'] === 'true' || row['has' + key.name + 'error'] === '1' || row['has' + key.name + 'error'] === 1
    },
    preValidationRow: function (row) {
      return true
    },
    updateTables () {
      this.$nextTick(() => {
        // Get all tables in the document
        var tables = document.querySelectorAll('table')

        tables.forEach(function (table) {
          // Apply the width to each cell in the table
          for (var i = 0; i < table.rows.length; i++) {
            let numCols = table.rows[i].cells.length
            for (var col = 0; col < numCols; col++) {}
          }
        })
      })
    },
    forceFormat (event) {
      // check if input value is a number value
      let isNumericInput
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        isNumericInput = true
      } else {
        isNumericInput = false
      }

      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // if inputed value is not a modifier or numeric strip it
      if (!isNumericInput && !isModifierKey) {
        event.preventDefault()
      }
    },

    formatPhoneInput (event) {
      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // dont change anything if modifier key inputed
      // eslint-disable-next-line block-spacing
      if (isModifierKey) {
        return
      }

      // get the input element and auto format value to (000) 000-0000
      const el = event.target
      const userInput = el.value.replace(/\D/g, '').substring(0, 10)
      const zip = userInput.substring(0, 3)
      const middle = userInput.substring(3, 6)
      const last = userInput.substring(6, 10)

      if (userInput.length > 6) {
        el.value = `(${zip}) ${middle}-${last}`
      } else if (userInput.length > 3) {
        el.value = `(${zip}) ${middle}`
      } else if (userInput.length > 0) {
        el.value = `(${zip}`
      }
    },

    arrowsWrapper: function (field, className) {
      if (this.sortedField === field && this.sortedDir) {
        return `${className} centralized`
      }
      return className
    },

    updateData: function () {
      const params = {
        sortField: this.sortedField,
        sort: this.sortedDir
      }

      this.$emit('on-update', params)
    },

    orderBy: function (field) {
      if (this.isFieldSortable(field)) {
        if (this.sortedField === field) {
          this.sortedDir = this.sortedDir === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortedDir = 'desc'
          this.sortedField = field
        }
        this.updateData()
      }
    },

    checkAll: function () {
      this.checkedAll = !this.checkedAll
      if (this.checkedAll) {
        this.itemsChecked = this.data
      } else {
        this.itemsChecked = []
      }
      this.$emit('on-check-all', this.itemsChecked)
    },

    checkItem: function (item) {
      const found = this.itemsChecked.find(
        (itemChecked) => itemChecked[this.trackBy] === item[this.trackBy]
      )
      if (found) {
        this.itemsChecked = this.itemsChecked.filter(
          (itemChecked) => itemChecked[this.trackBy] !== item[this.trackBy]
        )
        this.$emit('on-unchecked-item', item)
      } else {
        this.itemsChecked = [...this.itemsChecked, item]
        this.$emit('on-checked-item', item)
      }
    },

    isCheckedItem: function (item) {
      return !!this.itemsChecked.find(
        (itemChecked) => itemChecked[this.trackBy] === item[this.trackBy]
      )
    },

    isFieldSortable: function (field) {
      const foundHeader = this.headerFields.find((item) => item.name === field)
      return foundHeader && foundHeader.sortable
    },

    headerItemClass: function (item, className = []) {
      const classes = className.join(' ')
      return item && item.sortable ? classes : `${classes} no-sortable`
    },

    isFieldSpecial: (field) => field.indexOf('__') > -1,

    extractArgs: (string) => string.split(':')[1],

    extractActionID: (string) => {
      const list = string.split(':')
      return list.length === 3 ? list[2] : 'actions'
    },

    getColumnWidth: function (item) {
      if (item.name === '__slot:checkboxes') {
        return { width: '50px' }
      }

      if (item.name === 'actions') {
        return { width: '50px' }
      }

      return { width: item.width }
    },

    customElementName: ({ customElement, name }) =>
      typeof customElement === 'string' ? customElement : name,

    customHeaderName: ({ customHeader, name }) =>
      typeof customHeader === 'string' ? customHeader : `${name}:header`,

    showOrderArrow: function (item, sortDir) {
      if (this.onlyShowOrderedArrow) {
        return this.sortedField === item.name && this.sortedDir !== sortDir
      }
      return (
        this.sortedField !== item.name ||
        (this.sortedField === item.name && this.sortedDir === sortDir)
      )
    },

    addRow: function () {
      if (this.tableData.length >= this.limit) return

      this.$emit('on-add-row')
    }
  }
}
</script>

<style>
.no-borders td,
.no-borders th {
  border: 0px solid white !important;
  padding: 0.5rem !important;
  flex-grow: 1;
  font-weight: 500 !important;
  padding: 0.5rem !important;
}

.no-borders td:first-of-type,
.no-borders th:first-of-type {
  padding-left: 0rem !important;
}

.no-borders td:last-of-type,
.no-borders th:last-of-type {
  padding-right: 0rem !important;
}

table {
  width: 100% !important;
}

.no-borders td input,
.no-borders td .k-widget {
  width: 100%;
}

.dt-custom thead {
  width: 100% !important;
}

.dt-custom tr th:last-of-type {
  width: 50px !important;
  max-width: 50px !important;
}

.dt-custom tr td:last-of-type {
  width: 50px !important;
  max-width: 50px !important;
}

[is-invalid] {
  /* background-color: #f8d7da; */
}

[is-invalid-field] {
  border: 1px solid red !important;
}
</style>
